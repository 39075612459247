import 'reflect-metadata';
import {array, BaseTable, column} from "./base/base-table";
import {TestSubTable} from "./test-sub-table";
import {Validators} from "@angular/forms";

export class TestTable extends BaseTable {
  @column({field: 'customer', editor: 'text'})
  customer?: string;
  @column({field: 'valid', editor: 'boolean'})
  valid?: boolean;
  @array({field: 'subTables', type: 'TestSubTable'})
  subTables?: TestSubTable[];

  constructor(table: {
    id: string,
    created: Date,
    customer: string,
    valid: boolean,
    subTables: TestSubTable[]
  }) {
    super();
    this.id = table.id;
    this.created = table.created.toISOString();
    this.deleted = undefined;
    this.customer = table.customer;
    this.valid = table.valid;
    this.subTables = table.subTables;
  }

  override getFormControl() {
    const formControl = {
      customer: [this.customer, Validators.required],
      valid: this.valid,
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
