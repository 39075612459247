<nav class="navbar navbar-expand-lg p-0 m-0">
  <div class="container-fluid m-0 p-0">
    <a class="navbar-brand m-0 p-0" routerLink="/home">
      <ng-container *ngTemplateOutlet="ci"></ng-container>
    </a>

    <button
      class="navbar-toggler mx-2"
      type="button"
      aria-controls="navbarSupportedContent"
      [attr.aria-expanded]="!collapsed"
      aria-label="Toggle navigation"
      (click)="open(offCanvas)"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarSupportedContent">
      <ng-container *ngTemplateOutlet="navLinks"></ng-container>
    </div>
  </div>
</nav>


<ng-template #ci>
  <div class="d-flex flex-row align-items-center text-nowrap">
    <img #logo ngSrc="assets/images/JTI_Logo_RGB-1.png" alt="Logo" height="30" width="40"
         [style.margin.px]="logo.height * 0.598885793871866">
    <h2 class="m-0">{{title}}</h2>
  </div>
</ng-template>


<ng-template #offCanvas>
  <div class="offcanvas-header">
    <div class="offcanvas-title" id="offcanvas-basic-title">
      <ng-container *ngTemplateOutlet="ci"></ng-container>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="offcanvas-body">
    <ng-container [ngTemplateOutlet]="navLinks"
                  [ngTemplateOutletContext]="{$implicit: 'd-flex flex-column '}"></ng-container>
  </div>
</ng-template>


<ng-template #navLinks let-customClass>
  <!--<ul class="navbar-nav ms-auto">
    <li class="nav-item">
      <a class="nav-link active py-0" aria-current="page" href="home">Home</a>
    </li>

    <li class="nav-item">
      <a class="nav-link py-0" href="admin">Data Cockpit</a>
    </li>

    <li class="nav-item">
      <a class="nav-link py-0" href="sign-in">Sign In</a>
    </li>-->

  <ul ngbNav activeId="" [class]="'nav-highlight ms-auto mx-2 ' + customClass">
    <ng-container *ngFor="let link of links">
      <li [ngbNavItem]="link.route" [disabled]="link.disabled()" *ngIf="!link.subRoutes">
        <a ngbNavLink class="py-1 nav-item" [routerLink]="link.route" routerLinkActive="active" *ngIf="link.visible()">
          {{ link.title }}
        </a>
      </li>

      <li ngbDropdown *ngIf="link.subRoutes">
        <a class="nav-link py-1" ngbDropdownToggle id="matchDropdown" role="button">
          {{ link.title }}
        </a>
        <div ngbDropdownMenu aria-labelledby="matchDropdown" class="dropdown-menu py-2">
          <ng-container *ngFor="let subLink of link.subRoutes">
            <a ngbDropdownItem class="py-1 nav-item" [routerLink]="subLink.route" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}"
               *ngIf="subLink.visible()">
              {{ subLink.title }}
            </a>
          </ng-container>
        </div>
      </li>
    </ng-container>

    <li ngbDropdown *ngIf="userService.user">
      <a class="nav-link py-1" ngbDropdownToggle id="userDropdown" role="button" routerLinkActive="">
        <kendo-svg-icon [icon]="allSvgIcons.userIcon"></kendo-svg-icon>&#8203;
      </a>
      <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu p-2">
        <div class="text-center">{{userService.user.username}}</div>
        <div class="text-center">{{userService.user.fullname}}</div>
        <hr class="dropdown-divider">
        <kendo-button class="w-100" [primary]="true" look="flat" (click)="logout()">Logout</kendo-button>
      </div>
    </li>

    <!--<li class="nav-item" ngbDropdown>
      <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button"> Static </a>
      <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
        <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Action</a>
        <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Another action</a>
        <hr class="dropdown-divider">
        <a ngbDropdownItem href="#" (click)="$event.preventDefault()">Something else here</a>
      </div>
    </li>-->
  </ul>
</ng-template>
