import {BaseTable} from "./base/base-table";

export class TestSubTable extends BaseTable {

  constructor(table: { id: string, created: string }) {
    super();
    this.id = table.id;
    this.created = table.created;
  }
}
