import {Injectable} from '@angular/core';
import {HttpTableBase} from "../base/http-table-base";
import {TableService} from "./interfaces";
import {SumUpTransaction} from "../../models/tables/sum-up/sum-up-transaction";
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SumUpTransactionService extends HttpTableBase<SumUpTransaction> implements TableService {
  constructor(
    baseService: RestBaseService
  ) {
    super(
      {
        name: 'SumUpTransactionService',
        type: SumUpTransaction,
        canDeleteImport: true,
        baseService: baseService,
        apiName: 'SumUp Transaction Service',
        apiUrl: `${environment.backendApiUrl}/sumUpTransaction`,
        apiKey: environment.backendApiKey,
      }
    );
  }
}
