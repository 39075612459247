import {Injectable} from '@angular/core';
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";
import {TableService} from "./interfaces";
import {BlitzenActivation} from "../../models/tables/blitzenActivation";
import {HttpTableBase} from "../base/http-table-base";

@Injectable({
  providedIn: 'root'
})
export class BlitzenActivationService extends HttpTableBase<BlitzenActivation> implements TableService {
  constructor(
    baseService: RestBaseService
  ) {
    super(
      {
        name: 'BlitzenActivationService',
        type: BlitzenActivation,
        canDeleteImport: true,
        baseService: baseService,
        apiName: 'Blitzen Activation Service',
        apiUrl: `${environment.backendApiUrl}/blitzenActivation`,
        apiKey: environment.backendApiKey,
      }
    );
  }

  import(eventId: string, body: {
    brand: string,
    flight: string,
    apiKey: string,
    team_location?: string,
    promoter_email?: string,
    'uploaded_date >='?: string,
    'uploaded_date <='?: string
  }) {
    return this.post<unknown>(body, `/import?festivalId=${eventId}`, false);
  }
}
