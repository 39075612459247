import {UserService} from "../services/backend/user.service";
import {inject} from "@angular/core";
import {Router} from "@angular/router";

export const AuthGuard = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  if (userService.user) return true;

  return router.parseUrl('/sign-in');
}
