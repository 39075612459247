import {column, ImportTable} from "./base/base-table";
import 'reflect-metadata';

export class Activation extends ImportTable {
  /*@column({field: 'projectName', editor: 'text'})
  projectName?: string | null = null;
  @column({field: 'eventName', editor: 'text'})
  eventName?: string | null = null;
  @column({field: 'moduleName', editor: 'text'})
  moduleName?: string | null = null;*/
  @column({field: 'activationName', editor: 'text'})
  activationName?: string | null = null;
  @column({field: 'when', editor: 'date'})
  when?: string | null = null;
  @column({field: 'amount', editor: 'numeric'})
  amount?: number | null = null;

  override getFormControl() {
    const formControl = {
      // projectName: this.projectName,
      // eventName: this.eventName,
      // moduleName: this.moduleName,
      activationName: this.activationName,
      when: this.when ? new Date(this.when) : null,
      amount: this.amount
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
