import {Component, OnInit} from '@angular/core';
import {DatePipe, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet} from "@angular/common";
import {NgbProgressbar} from "@ng-bootstrap/ng-bootstrap";
import {EventService} from "../services/backend/event.service";
import {Festival} from "../models/tables/festival";
import {IntlModule} from "@progress/kendo-angular-intl";
import {RouterLink} from "@angular/router";
import {finalize} from "rxjs";
import {ImportSettingsChipListComponent, LoadingPanelComponent} from "@fret/widgets";
import {ChipModule} from "@progress/kendo-angular-buttons";

@Component({
  standalone: true,
  selector: 'app-home',
  templateUrl: './home.component.html',
    imports: [
        NgForOf,
        NgTemplateOutlet,
        NgbProgressbar,
        IntlModule,
        NgOptimizedImage,
        RouterLink,
        LoadingPanelComponent,
        NgIf,
        ChipModule,
        ImportSettingsChipListComponent,
        DatePipe
    ],
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  events: Festival[] = [];
  loading = true;

  constructor(
    protected eventService: EventService
  ) {
  }

  ngOnInit(): void {
    this.eventService
      .getEvents()
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe(value => {
        if (!value.body) {
          this.loading = false;
          return;
        }
        this.events = value.body;
        this.loading = false;
      });
  }
}
