import {SVGIcon} from "@progress/kendo-angular-icons";

export const pencil1Icon: SVGIcon = {
  name: 'pencil-1',
  content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>pencil-1</title><path class="a" d="M22.19,1.81a3.638,3.638,0,0,0-5.169.035l-14.5,14.5L.75,23.25l6.905-1.771,14.5-14.5A3.638,3.638,0,0,0,22.19,1.81Z"/><line class="a" x1="16.606" y1="2.26" x2="21.74" y2="7.394"/><line class="a" x1="14.512" y1="4.354" x2="19.646" y2="9.488"/><line class="a" x1="2.521" y1="16.345" x2="7.66" y2="21.474"/></svg>',
  viewBox: '0 0 24 24'
};

export const undoIcon: SVGIcon = {
  name: 'undo',
  content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>undo</title><polyline class="a" points="0.75 0.748 0.75 8.248 8.25 8.248"/><path class="a" d="M12,23.248a11.25,11.25,0,1,0-10.6-15"/></svg>',
  viewBox: '0 0 24 24'
};

export const cogIcon: SVGIcon = {
  name: 'cog',
  content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>cog</title><path class="a" d="M10.546,2.438a1.957,1.957,0,0,0,2.908,0L14.4,1.4a1.959,1.959,0,0,1,3.41,1.413l-.071,1.4A1.958,1.958,0,0,0,19.79,6.267l1.4-.071A1.959,1.959,0,0,1,22.6,9.606l-1.042.94a1.96,1.96,0,0,0,0,2.909l1.042.94a1.959,1.959,0,0,1-1.413,3.41l-1.4-.071a1.958,1.958,0,0,0-2.056,2.056l.071,1.4A1.959,1.959,0,0,1,14.4,22.6l-.941-1.041a1.959,1.959,0,0,0-2.908,0L9.606,22.6A1.959,1.959,0,0,1,6.2,21.192l.072-1.4a1.958,1.958,0,0,0-2.056-2.056l-1.4.071A1.958,1.958,0,0,1,1.4,14.4l1.041-.94a1.96,1.96,0,0,0,0-2.909L1.4,9.606A1.958,1.958,0,0,1,2.809,6.2l1.4.071A1.958,1.958,0,0,0,6.267,4.211L6.2,2.81A1.959,1.959,0,0,1,9.606,1.4Z"/><circle class="a" cx="12" cy="12.001" r="4.5"/></svg>',
  viewBox: '0 0 24 24'
};

export const arrowDownIcon: SVGIcon = {
  name: 'arrow-down',
  content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>download-button</title><line class="a" x1="12.002" y1="0.751" x2="12.002" y2="15.751"/><polyline class="a" points="7.502 11.251 12.002 15.751 16.502 11.251"/><path class="a" d="M15.752,4.5H18a3,3,0,0,1,3,3v12.75a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V7.5a3,3,0,0,1,3-3h2.25"/></svg>',
  viewBox: '0 0 24 24'
};
export const xlsIcon: SVGIcon = {
  name: 'xls',
  content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>office-file-xls</title><line class="a" x1="4.503" y1="11.998" x2="7.503" y2="19.498"/><line class="a" x1="7.503" y1="11.998" x2="4.503" y2="19.498"/><path class="a" d="M10.5,12v6A1.5,1.5,0,0,0,12,19.5h1.5"/><path class="a" d="M19.5,12H18a1.5,1.5,0,0,0-1.5,1.5c0,2.25,3,2.25,3,4.5A1.5,1.5,0,0,1,18,19.5H16.5"/><path class="a" d="M22.814,5.559a1.5,1.5,0,0,1,.439,1.06V21A2.25,2.25,0,0,1,21,23.248H3A2.25,2.25,0,0,1,.753,21V3A2.25,2.25,0,0,1,3,.748H17.382a1.5,1.5,0,0,1,1.06.439Z"/><path class="a" d="M17.253.748v4.5a1.5,1.5,0,0,0,1.5,1.5h4.5"/></svg>',
  viewBox: '0 0 24 24'
};

export const cloudIcon: SVGIcon = {
  name: 'cloud',
  content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>cloud</title><path class="a" d="M18.375,19.5a4.875,4.875,0,1,0-2.764-8.885A7.5,7.5,0,1,0,8.25,19.5Z"/></svg>',
  viewBox: '0 0 24 24'
};

export const checkIcon: SVGIcon = {
  name: 'check',
  content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>check-circle</title><path class="a" d="M6.75,9l3.294,4.611a1.526,1.526,0,0,0,2.414.09L23.25.749"/><path class="a" d="M16.783,3.824A10.487,10.487,0,1,0,20.8,8.377"/></svg>',
  viewBox: '0 0 24 24'
};

export const arrowLeft1Icon: SVGIcon = {
  name: 'arrow-left-1',
  content: '<svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill-rule:evenodd;}</style></defs><title>arrow-left-1</title><path class="cls-1" d="M16.25,23.25,5.53,12.53a.749.749,0,0,1,0-1.06L16.25.75"/></svg>',
  viewBox: '0 0 24 24'
};
