import {InjectionToken} from "@angular/core";
import {TestTableService} from "./test-table.service";
import {GetSystemsService} from "./get-systems.service";
import {GetSystemsTransactionService} from "./get-systems-transaction.service";
import {BlitzenService} from "./blitzen.service";
import {EventService} from "./event.service";
import {ActivationService} from "./activation.service";
import {ProductService} from "./product.service";
import {PromoterService} from "./promoter.service";
import {PromoterWorkingHourService} from "./promoter-working-hour.service";
import {ModuleService} from "./module.service";
import {SalesService} from "./sales.service";
import {BlitzenActivationService} from "./blitzen-activation.service";
import {SumUpTransactionService} from "./sum-up-transaction.service";
import {SumUpProductService} from "./sum-up-product.service";

export const InjectionTokens = {
  TestTableService: new InjectionToken<TestTableService>('TestTableService'),
  GetSystemsService: new InjectionToken<GetSystemsService>('GetSystemsService'),
  GetSystemsTransactionService: new InjectionToken<GetSystemsTransactionService>('GetSystemsTransactionService'),
  BlitzenService: new InjectionToken<BlitzenService>('BlitzenService'),
  BlitzenActivationService: new InjectionToken<BlitzenActivationService>('BlitzenActivationService'),
  EventService: new InjectionToken<EventService>('EventService'),
  ActivationService: new InjectionToken<ActivationService>('ActivationService'),
  ProductService: new InjectionToken<ProductService>('ProductService'),
  PromoterService: new InjectionToken<PromoterService>('PromoterService'),
  PromoterWorkingHourService: new InjectionToken<PromoterWorkingHourService>('PromoterWorkingHourService'),
  ModuleService: new InjectionToken<ModuleService>('ModuleService'),
  SalesService: new InjectionToken<SalesService>('SalesService'),
  SumUpTransactionService: new InjectionToken<SumUpTransactionService>('SumUpTransactionService'),
  SumUpProductService: new InjectionToken<SumUpProductService>('SumUpProductService'),
}

export const InjectionTokenArray = [
  {token: InjectionTokens.TestTableService, name: 'TestTableService'},
  {token: InjectionTokens.GetSystemsService, name: 'GetSystemsService'},
  {token: InjectionTokens.GetSystemsTransactionService, name: 'GetSystemsTransactionService'},
  {token: InjectionTokens.BlitzenService, name: 'BlitzenService'},
  {token: InjectionTokens.BlitzenActivationService, name: 'BlitzenActivationService'},
  {token: InjectionTokens.EventService, name: 'EventService'},
  {token: InjectionTokens.ActivationService, name: 'ActivationService'},
  {token: InjectionTokens.ProductService, name: 'ProductService'},
  {token: InjectionTokens.PromoterService, name: 'PromoterService'},
  {token: InjectionTokens.PromoterWorkingHourService, name: 'PromoterWorkingHoursService'},
  {token: InjectionTokens.ModuleService, name: 'ModuleService'},
  {token: InjectionTokens.SalesService, name: 'SalesService'},
  {token: InjectionTokens.SumUpTransactionService, name: 'SumUpService'},
  {token: InjectionTokens.SumUpProductService, name: 'SumUpProductService'},
]

export const GetInjectionTokenByName = (name: string) => {
  return InjectionTokenArray.find(value => value.name === name)?.token;
}
