import {Injectable} from '@angular/core';
import {HttpBase} from "../base/http-base";
import {environment} from "../../environments/environment";
import {RestBaseService} from "../base/rest-base.service";
import {Festival} from "../../models/tables/festival";
import {
  BlitzenImport,
  GetSystemsImport,
  ImportConfig,
  ImportSource,
  ImportSources,
  instanceOfImportSource, SumUpImport
} from "../../models/import/import-config";
import {TableService} from "./interfaces";
import {Observable, of, switchMap} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EventService extends HttpBase implements TableService {
  name = 'EventService';
  prototype = new Festival();
  canDeleteImport = false;

  constructor(
    baseService: RestBaseService
  ) {
    super(
      baseService,
      'Event Service',
      `${environment.backendApiUrl}/event`,
      environment.backendApiKey
    );
  }

  calculateImportStatus(config?: ImportConfig) {
    if (!config) return 0;
    /*config.getSystemsImport = {
      id: undefined,
      finished: true,
      note: undefined,
      importMarker: undefined,
    };*/

    return this.getImportFinishedCount(config) / this.getImportCount() * 100;
  }

  getImportCount() {
    return Object.values(ImportSources).length;
  }

  getImportFinishedCount(config?: ImportConfig) {
    if (!config) return 0;

    const map = new Map(Object.entries(config));
    let doneCount = 0;
    map.forEach(value => {
      if (!value) return;

      let valid: boolean[] = [];
      new Map(Object.entries(value)).forEach((_, key) => {
        if (!(value instanceof Object)) return;

        valid.push(instanceOfImportSource(value, key));
      });

      if (valid.length === 0 || valid.find(b => !b)) return;

      if ((value as ImportSource)?.finished) doneCount++;
    });

    return doneCount;
  }

  getEvents() {
    return this.get<Festival[]>();
  }

  getEvent(id: string) {
    return this.getById<Festival>(id);
  }

  putEvent(event: Festival) {
    return this.put<Festival>(event, `/${event.id}`, false);
  }

  getData(skip = 0, take = 100) {
    return this.get<Festival[]>(`?skip=${skip}&take=${take}`)
      .pipe(
        switchMap(value => {
          if (!value.body) return of(value);

          let models = new Array<Festival>();
          value.body.forEach(model => models.push(Object.assign(new Festival(), model)));
          Object.assign(value.body, models);

          return of(value);
        })
      );
  }

  updateGetSystemsImport(eventId: string, importSource: GetSystemsImport) {
    return this.put(importSource, `/importConfig/${eventId}/getSystems`, false);
  }

  updateBlitzenImport(eventId: string, importSource: BlitzenImport) {
    return this.put(importSource, `/importConfig/${eventId}/blitzenData`, false);
  }

  updateBlitzenActivationImport(eventId: string, importSource: BlitzenImport) {
    return this.put(importSource, `/importConfig/${eventId}/blitzenActivation`, false);
  }

  updateSumUpImport(eventId: string, importSource: SumUpImport) {
    return this.put(importSource, `/importConfig/${eventId}/sumUp`, false);
  }

  deleteGetSystemsImport(eventId: string) {
    return this.delete('', `/importConfig/${eventId}/getSystems`, false);
  }

  deleteBlitzenImport(eventId: string) {
    return this.delete('', `/importConfig/${eventId}/blitzenData`, false);
  }

  deleteBlitzenActivationImport(eventId: string) {
    return this.delete('', `/importConfig/${eventId}/blitzenActivation`, false);
  }

  deleteSumUpImport(eventId: string) {
    return this.delete('', `/importConfig/${eventId}/sumUp`, false);
  }

  deleteImport(importId: string) {
    return this.delete(importId, '/import');
  }

  deleteFestival(festivalId: string): Observable<HttpResponse<unknown>> {
    return this.delete(festivalId);
  }

  importApi(id: string, endpoint: string) {
    return this.post({}, `/import/${id}/${endpoint}`);
  }
}
