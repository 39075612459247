import {Injectable} from '@angular/core';
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";
import {TableService} from "./interfaces";
import {Module} from "../../models/tables/module";
import {HttpTableBase} from "../base/http-table-base";

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends HttpTableBase<Module> implements TableService {
  constructor(
    baseService: RestBaseService
  ) {
    super(
      {
        name: 'ModuleService',
        type: Module,
        canDeleteImport: true,
        baseService: baseService,
        apiName: 'Module Service',
        apiUrl: `${environment.backendApiUrl}/module`,
        apiKey: environment.backendApiKey,
      }
    );
  }
}
