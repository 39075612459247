import {column, ImportTable} from "./base/base-table";

export class BlitzenActivation extends ImportTable {
  @column({field: 'brand', editor: 'text'})
  brand?: string | null = null;
  @column({field: 'flight', editor: 'text'})
  flight?: string | null = null;
  @column({field: 'team_location', editor: 'text'})
  team_location?: string | null = null;
  @column({field: 'activation_name', editor: 'text'})
  activation_name?: string | null = null;
  @column({field: 'hash_data_id', editor: 'text'})
  hash_data_id?: string | null = null;
  @column({field: 'qr_code_id', editor: 'text'})
  qr_code_id?: string | null = null;
  @column({field: 'date', editor: 'date'})
  date?: string | null = null;

  override getFormControl() {
    const formControl = {
      brand: this.brand,
      flight: this.flight,
      team_location: this.team_location,
      activation_name: this.activation_name,
      hash_data_id: this.hash_data_id,
      qr_code_id: this.qr_code_id,
      date: [this.date ? new Date(this.date) : null]
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
