import {Injectable} from '@angular/core';
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";
import {TableService} from "./interfaces";
import {HttpTableBase} from "../base/http-table-base";
import {GetSystemsTransaction} from "../../models/tables/get-systems/get-systmes-transaction";
import {Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {Operation} from "fast-json-patch/commonjs/core";
import {GetSystemsTransactionService} from "./get-systems-transaction.service";

@Injectable({
  providedIn: 'root'
})
export class GetSystemsService extends HttpTableBase<GetSystemsTransaction> implements TableService {
  constructor(
    baseService: RestBaseService,
    private getSystemsTransactionService: GetSystemsTransactionService
  ) {
    super(
      {
        name: 'GetSystemsService',
        type: GetSystemsTransaction,
        canDeleteImport: true,
        baseService: baseService,
        apiName: 'GetSystems Service',
        apiUrl: `${environment.backendApiUrl}/getSystems`,
        apiKey: environment.backendApiKey,
      }
    );
  }

  override deleteImport(importId: string): Observable<HttpResponse<unknown>> {
    return this.getSystemsTransactionService.deleteImport(importId);
  }

  override getData(skip?: number, take?: number, festivalId?: string): Observable<HttpResponse<unknown[]>> {
    return this.getSystemsTransactionService.getData(skip, take, festivalId);
  }

  override patch<T>(id: string, body: Operation[]): Observable<HttpResponse<T>> {
    return this.getSystemsTransactionService.patch<T>(id, body);
  }

  override deleteFestival(festivalId: string): Observable<HttpResponse<unknown>> {
    return this.getSystemsTransactionService.deleteFestival(festivalId);
  }

  getRawProjects() {
    return super.get<{ name?: string, id?: string }[]>('/raw/projects');
  }
}
