import {Activation} from "./activation";
import {Sales} from "./sales";
import {Promoter} from "./promoter";
import {array, column, ImportTable} from "./base/base-table";
import 'reflect-metadata';

export class Module extends ImportTable {
  @column({field: 'name', editor: 'text'})
  name?: string | null = null;
  @column({field: 'description', editor: 'text'})
  description?: string | null = null;
  @column({field: 'location', editor: 'text'})
  location?: string | null = null;
  @column({field: 'startDate', editor: 'date'})
  startDate?: string | null = null;
  @column({field: 'endDate', editor: 'date'})
  endDate?: string | null = null;
  @array({field: 'activations', type: 'Activation'})
  activations?: Activation[] | null = null;
  @array({field: 'promoters', type: 'Promoter'})
  promoters?: Promoter[] | null = null;
  @array({field: 'sales', type: 'Sales'})
  sales?: Sales[] | null = null;

  override getFormControl() {
    const formControl = {
      name: this.name,
      description: this.description,
      location: this.location,
      startDate: this.startDate ? new Date(this.startDate) : null,
      endDate: this.endDate ? new Date(this.endDate) : null,
      activations: [this.activations],
      promoters: [this.promoters],
      sales: [this.sales],
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
