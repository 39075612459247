/// <reference types="@angular/localize" />

import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideHttpClient} from "@angular/common/http";
import {provideRouter} from "@angular/router";
import {AppRoutes} from "./app/app.routes";
import {importProvidersFrom, LOCALE_ID} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {MyIconService} from "./services/my-icon.service";
import {ICON_SETTINGS, IconSettingsService} from "@progress/kendo-angular-icons";
import {InjectionTokens} from "./services/backend/injection-tokens";
import {TestTableService} from "./services/backend/test-table.service";
import {NotificationModule} from "@progress/kendo-angular-notification";
import {GetSystemsService} from "./services/backend/get-systems.service";
import {GetSystemsTransactionService} from "./services/backend/get-systems-transaction.service";
import {BlitzenService} from "./services/backend/blitzen.service";
import {EventService} from "./services/backend/event.service";
import {ActivationService} from "./services/backend/activation.service";
import {ProductService} from "./services/backend/product.service";
import {PromoterService} from "./services/backend/promoter.service";
import {PromoterWorkingHourService} from "./services/backend/promoter-working-hour.service";
import {ModuleService} from "./services/backend/module.service";
import {SalesService} from "./services/backend/sales.service";
import {JwtHelperService, JwtModule} from "@auth0/angular-jwt";
import {BlitzenActivationService} from "./services/backend/blitzen-activation.service";
import {IntlModule} from "@progress/kendo-angular-intl";
import '@progress/kendo-angular-intl/locales/de/all';
import '@angular/common/locales/global/de'
import {SumUpTransactionService} from "./services/backend/sum-up-transaction.service";
import {SumUpProductService} from "./services/backend/sum-up-product.service";

bootstrapApplication(
  AppComponent,
  {
    providers: [
      provideAnimations(),
      provideHttpClient(),
      provideRouter(AppRoutes),
      importProvidersFrom(
        ReactiveFormsModule,
        NotificationModule,
        JwtModule,
        IntlModule
      ),
      {provide: LOCALE_ID, useValue: 'de'},
      {provide: ICON_SETTINGS, useValue: {type: 'svg'}},
      {provide: IconSettingsService, useClass: MyIconService},
      {provide: InjectionTokens.TestTableService, useExisting: TestTableService},
      {provide: InjectionTokens.GetSystemsService, useExisting: GetSystemsService},
      {provide: InjectionTokens.GetSystemsTransactionService, useExisting: GetSystemsTransactionService},
      {provide: InjectionTokens.BlitzenService, useExisting: BlitzenService},
      {provide: InjectionTokens.BlitzenActivationService, useExisting: BlitzenActivationService},
      {provide: InjectionTokens.EventService, useExisting: EventService},
      {provide: InjectionTokens.ActivationService, useExisting: ActivationService},
      {provide: InjectionTokens.ProductService, useExisting: ProductService},
      {provide: InjectionTokens.PromoterService, useExisting: PromoterService},
      {provide: InjectionTokens.PromoterWorkingHourService, useExisting: PromoterWorkingHourService},
      {provide: InjectionTokens.ModuleService, useExisting: ModuleService},
      {provide: InjectionTokens.SalesService, useExisting: SalesService},
      {provide: InjectionTokens.SumUpTransactionService, useExisting: SumUpTransactionService},
      {provide: InjectionTokens.SumUpProductService, useExisting: SumUpProductService},
      {provide: JwtHelperService, useFactory: () => new JwtHelperService()},
    ]
  }
).catch(err => console.error(err));
