import {Product} from "./product";
import {Module} from "./module";
import {array, column, ImportTable} from "./base/base-table";
import 'reflect-metadata';

export class Sales extends ImportTable {
  @column({field: 'when', editor: 'date'})
  when?: string | null = null;
  @array({field: 'product', type: 'Product'})
  product?: Product | null = null;
  @array({field: 'module', type: 'Module'})
  module?: Module | null = null;
  @column({field: 'moduleId', editor: 'text'})
  moduleId?: string | null = null;
  @column({field: 'productId', editor: 'text'})
  productId?: string | null = null;
  @column({field: 'quantity', editor: 'numeric'})
  quantity?: number | null = null;

  override getFormControl() {
    const formControl = {
      when: this.when ? new Date(this.when) : null,
      moduleId: this.moduleId,
      productId: this.productId,
      quantity: this.quantity,
      product: this.product,
      module: this.module,
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
