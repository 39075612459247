import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NotificationRef, NotificationService} from "@progress/kendo-angular-notification";

@Injectable({
  providedIn: 'root'
})
export class RestBaseService {
  notificationRef?: NotificationRef;

  constructor(
    public httpClient: HttpClient,
    public notificationService: NotificationService,
  ) {
  }
}
