import {Injectable} from '@angular/core';
import {TestTable} from "../../models/tables/test-table";
import {TestSubTable} from "../../models/tables/test-sub-table";
import {of} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TestTableService {
  name = 'TestTableService'
  prototype = new TestTable({
    id: '',
    created: new Date(),
    customer: '',
    valid: false,
    subTables: [new TestSubTable({id: '', created: ''})],
  });
  canDeleteImport = false;

  constructor() {
  }

  getData(skip = 0, take = 100) {
    return of(new HttpResponse({
      body: [
        new TestTable({
          id: '1234',
          created: new Date(),
          customer: 'Herr Mustermann',
          valid: true,
          subTables: [new TestSubTable({id: '1234', created: ''}), new TestSubTable({id: '1234', created: ''})],
        }),
        new TestTable({
          id: '5678',
          created: new Date(),
          customer: 'Frau Mustermann',
          valid: true,
          subTables: [new TestSubTable({id: '1234', created: ''}), new TestSubTable({id: '1234', created: ''})],
        })
      ]
    }));
  }

  patch(id: string, body: unknown) {
    return of(new HttpResponse());
  }

  deleteImport(importId: string) {
    return of(new HttpResponse());
  }

  deleteFestival(festivalId: string) {
    return of(new HttpResponse());
  }
}
