<div class="container position-relative h-100">
  <app-loading-panel [showMask]="false" *ngIf="loading"></app-loading-panel>

  <div class="row align-items-center mb-5" *ngFor="let event of events">
    <ng-container [ngTemplateOutlet]="eventTemplate" [ngTemplateOutletContext]="{$implicit: event}"></ng-container>
  </div>
</div>


<ng-template #eventTemplate let-event>
  <div class="col-12 col-lg-5 col-xl-4 ms-xl-auto">
    <div class="container-fluid">
      <div class="row justify-content-center justify-content-lg-start align-items-center">
        <div class="col-auto me-3 event-info" [class.d-none]="!event.image">
          <img ngSrc="{{event.image}}" width="64" height="64" alt="Event Thumbnail" *ngIf="event.image">
        </div>

        <div class="col-auto">
          <div class="row">
            <div class="col text-primary fs-5">
              <a routerLink="/admin" [queryParams]="{eventId: event.id}">{{event.name}}</a>
            </div>
          </div>

          <div class="row">
            <div class="col">{{ event.fromDate | date: 'shortDate' }}
              - {{ event.city }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-7 col-xl-8 ms-xl-0 ms-auto mt-2 mt-lg-0">
    <div class="container-fluid">
      <div class="row justify-content-center justify-content-lg-start align-items-center">
        <!--<div class="col">
          <label for="progressbar">Data sources</label>
          <ngb-progressbar id="progressbar" type="primary"
                           [value]="eventService.calculateImportStatus(event?.importConfig)"
                           [showValue]="false"></ngb-progressbar>
        </div>

        <div class="col-auto text-primary fs-5">
          {{eventService.getImportFinishedCount(event?.importConfig)}}/{{eventService.getImportCount()}}
        </div>-->
        <div class="col-auto">
          <app-import-settings-chip-list [event]="event"></app-import-settings-chip-list>
        </div>
      </div>
    </div>
  </div>
</ng-template>
