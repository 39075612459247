import {Injectable} from '@angular/core';
import {HttpBase} from "../base/http-base";
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";
import {RoleType, User} from "../../models/user";
import {catchError, tap, throwError, timer} from "rxjs";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Operation} from "fast-json-patch/commonjs/core";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpBase {
  private _user?: User | null;

  roles: { roleType: RoleType, name: string }[] = [
    {roleType: RoleType.unknown, name: 'Unknown'},
    {roleType: RoleType.guest, name: 'Guest'},
    {roleType: RoleType.user, name: 'User'},
    {roleType: RoleType.admin, name: 'Admin'},
    {roleType: RoleType.developer, name: 'Developer'},
  ];

  get user(): User | null {
    if (!this._user) {
      this._user = this.jwtService.decodeToken(this.token);
    }

    return this._user;
  }

  get isUnknown() {
    return this.user ? this.user?.roleType <= RoleType.unknown : false;
  }

  get isGuest() {
    return this.user ? this.user?.roleType <= RoleType.guest : false;
  }

  get isUser() {
    return this.user ? this.user?.roleType <= RoleType.user : false;
  }

  get isAdmin() {
    return this.user ? this.user?.roleType <= RoleType.admin : false;
  }

  get isDev() {
    return this.user ? this.user?.roleType <= RoleType.developer : false;
  }

  constructor(
    private jwtService: JwtHelperService,
    baseService: RestBaseService,
  ) {
    super(
      baseService,
      'User Service',
      `${environment.backendApiUrl}/user`,
      environment.backendApiKey
    );
  }

  getUsers(skip = 0, take = 100) {
    return this.get<User[]>(`?skip=${skip}&take=${take}`);
  }

  patchUser(id: string, body: Operation[]) {
    return this.patch(id, body);
  }

  signIn(credentials: { userName: string | null, password: string | null }) {
    return this.post(credentials, '/login', false)
      .pipe(
        tap(response => {
          const authHeader = response.headers.get('Authorization');
          this.token = authHeader?.replace(RegExp('[a-zA-Z]* '), '') ?? '';
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status != 401) {
            return this.defaultHttpErrorHandler(error);
          }

          const errorMessage = `Mail address or password incorrect`;
          console.error(`${this.apiName} returned code ${error.status}, body was: `, error.error);

          this.baseService.notificationRef?.hide();

          timer(500).subscribe(() => {
            this.baseService.notificationRef = this.baseService.notificationService.show({
              content: errorMessage,
              type: {style: 'error', icon: true},
              position: {horizontal: 'center', vertical: 'bottom'},
              hideAfter: 10000,
            });
          });

          return throwError(() => new Error(errorMessage));
        })
      );
  }

  register(signUp: { userName: string | null, password: string | null, fullName: string | null }) {
    return this.post(signUp, '/register', false)
      .pipe(
        tap(response => {
          const authHeader = response.headers.get('Authorization');
          this.token = authHeader?.replace(RegExp('[a-zA-Z]* '), '') ?? '';
        })
      );
  }

  logout() {
    this._user = undefined;
    this.token = '';
  }
}
