import {HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {RestBaseService} from "./rest-base.service";
import {catchError, Observable, tap, throwError, timer} from "rxjs";
import {Operation} from "fast-json-patch/commonjs/core";

export const TokenStore = 'token';

export abstract class HttpBase {
  private _token?: string | null;

  protected defaultHeaders = new HttpHeaders({'accept': 'application/json'});

  protected set token(value: string) {
    this._token = value;
    localStorage.setItem(TokenStore, value);
  }

  protected get token() {
    if (!this._token) {
      this._token = localStorage.getItem(TokenStore);
    }

    return this._token ?? '';
  };

  protected constructor(
    protected readonly baseService: RestBaseService,
    protected readonly apiName: string,
    protected readonly apiUrl: string,
    protected readonly apiKey?: string,
  ) {
    this.defaultHeaders = apiKey
      ? this.defaultHeaders.append('API-KEY', apiKey)
      : this.defaultHeaders;
  }

  private createHeaders() {
    return this.defaultHeaders.append('Authorization', `Bearer ${this.token}`);
  }

  protected getById<T>(id: string, endpoint = '', defaultErrorHandling = true) {
    let request = this.baseService.httpClient
      .get<T>(
        `${this.apiUrl}${endpoint}/${id}`,
        {
          headers: this.createHeaders(),
          observe: 'response',
        }
      );

    request = this.addDefaultPipe(request);

    return defaultErrorHandling ? request.pipe(catchError(this.defaultHttpErrorHandler)) : request;
  }

  protected get<T>(endpoint = '/', defaultErrorHandling = true) {
    let request = this.baseService.httpClient
      .get<T>(
        `${this.apiUrl}${endpoint}`,
        {
          headers: this.createHeaders(),
          observe: 'response',
        }
      );

    request = this.addDefaultPipe(request);

    return defaultErrorHandling ? request.pipe(catchError(this.defaultHttpErrorHandler)) : request;
  }

  protected post<T>(body: unknown, endpoint = '/', defaultErrorHandling = true) {
    let request = this.baseService.httpClient
      .post<T>(
        `${this.apiUrl}${endpoint}`,
        body,
        {
          headers: this.createHeaders(),
          observe: 'response',
        }
      );

    request = this.addDefaultPipe(request);

    return defaultErrorHandling ? request.pipe(catchError(this.defaultHttpErrorHandler)) : request;
  }

  protected put<T>(body: unknown, endpoint = '/', defaultErrorHandling = true) {
    let request = this.baseService.httpClient
      .put<T>(
        `${this.apiUrl}${endpoint}`,
        body,
        {
          headers: this.createHeaders(),
          observe: 'response',
        }
      );

    request = this.addDefaultPipe(request);

    return defaultErrorHandling ? request.pipe(catchError(this.defaultHttpErrorHandler)) : request;
  }

  public patch<T>(id: string, body: Operation[] | unknown, endpoint = '', defaultErrorHandling = true) {
    let request = this.baseService.httpClient
      .patch<T>(
        `${this.apiUrl}/${id}/${endpoint}`.replace(new RegExp('/?$'), ''),
        body,
        {
          headers: this.createHeaders(),
          observe: 'response',
        }
      );

    request = this.addDefaultPipe(request);

    return defaultErrorHandling ? request.pipe(catchError(this.defaultHttpErrorHandler)) : request;
  }

  protected delete(id: string, endpoint = '', defaultErrorHandling = true) {
    let request = this.baseService.httpClient
      .delete<unknown>(
        `${this.apiUrl}${endpoint}/${id}`,
        {
          headers: this.createHeaders(),
          observe: 'response',
        }
      );

    request = this.addDefaultPipe(request);

    return defaultErrorHandling ? request.pipe(catchError(this.defaultHttpErrorHandler)) : request;
  }

  protected defaultHttpErrorHandler = (error: HttpErrorResponse) => {
    const errorMessage = `An unexpected error occured. Error code ${error.status}`;

    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`${this.apiName} returned code ${error.status}, body was: `, error.error);
    }

    this.baseService.notificationRef?.hide();

    timer(500).subscribe(() => {
      this.baseService.notificationRef = this.baseService.notificationService.show({
        content: errorMessage,
        type: {style: 'error', icon: true},
        position: {horizontal: 'center', vertical: 'bottom'},
        hideAfter: 10000,
      });
    });

    // Return an observable with a user-facing error message.
    return throwError(() => new Error(errorMessage));
  };

  private addDefaultPipe(request: Observable<any>) {
    return request.pipe(
      tap(() => this.baseService.notificationRef?.hide())
    );
  }

}

