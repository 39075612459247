import {Injectable} from '@angular/core';
import {TableService} from "./interfaces";
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";
import {Activation} from "../../models/tables/activation";
import {HttpTableBase} from "../base/http-table-base";

@Injectable({
  providedIn: 'root'
})
export class ActivationService extends HttpTableBase<Activation> implements TableService {
  constructor(
    baseService: RestBaseService
  ) {
    super(
      {
        name: 'ActivationService',
        type: Activation,
        canDeleteImport: true,
        baseService: baseService,
        apiName: 'Activation Service',
        apiUrl: `${environment.backendApiUrl}/activation`,
        apiKey: environment.backendApiKey,
      }
    );
  }
}
