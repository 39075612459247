import {Injectable} from '@angular/core';
import {HttpTableBase} from "../base/http-table-base";
import {TableService} from "./interfaces";
import {SumUpProduct} from "../../models/tables/sum-up/sum-up-product";
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SumUpProductService extends HttpTableBase<SumUpProduct> implements TableService {
  constructor(
    baseService: RestBaseService
  ) {
    super(
      {
        name: 'SumUpProductService',
        type: SumUpProduct,
        canDeleteImport: false,
        baseService: baseService,
        apiName: 'SumUp Product Service',
        apiUrl: `${environment.backendApiUrl}/sumUpProduct`,
        apiKey: environment.backendApiKey,
      }
    );
  }
}
