import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {RestBaseService} from "../base/rest-base.service";
import {GetSystemsTransaction} from "../../models/tables/get-systems/get-systmes-transaction";
import {TableService} from "./interfaces";
import {HttpTableBase} from "../base/http-table-base";

@Injectable({
  providedIn: 'root'
})
export class GetSystemsTransactionService extends HttpTableBase<GetSystemsTransaction> implements TableService {
  constructor(
    baseService: RestBaseService
  ) {
    super(
      {
        name: 'GetSystemsTransactionService',
        type: GetSystemsTransaction,
        canDeleteImport: false,
        baseService: baseService,
        apiName: 'GetSystems Transaction Service',
        apiUrl: `${environment.backendApiUrl}/getSystemsTransactions`,
        apiKey: environment.backendApiKey,
      }
    );
  }
}
