import {array, column, ImportTable} from "../base/base-table";
import {SumUpProduct} from "./sum-up-product";

export class SumUpTransaction extends ImportTable {
  @column({field: 'transactionId', editor: 'text'})
  transactionId?: string | null = null;
  @column({field: 'transactionCode', editor: 'text'})
  transactionCode?: string | null = null;
  @column({field: 'merchantCode', editor: 'text'})
  merchantCode?: string | null = null;
  @column({field: 'username', editor: 'text'})
  username?: string | null = null;
  @column({field: 'amount', editor: 'numeric'})
  amount?: number | null = null;
  @column({field: 'vatAmount', editor: 'numeric'})
  vatAmount?: number | null = null;
  @column({field: 'tipAmount', editor: 'numeric'})
  tipAmount?: number | null = null;
  @column({field: 'currency', editor: 'text'})
  currency?: string | null = null;
  @column({field: 'timestamp', editor: 'text'})
  timestamp?: string | null = null;
  @column({field: 'lat', editor: 'numeric'})
  lat?: number | null = null;
  @column({field: 'lon', editor: 'numeric'})
  lon?: number | null = null;
  @column({field: 'horizontalAccuracy', editor: 'numeric'})
  horizontalAccuracy?: number | null = null;
  @column({field: 'status', editor: 'text'})
  status?: string | null = null;
  @column({field: 'paymentType', editor: 'text'})
  paymentType?: string | null = null;
  @column({field: 'simplePaymentType', editor: 'text'})
  simplePaymentType?: string | null = null;
  @column({field: 'entryMode', editor: 'text'})
  entryMode?: string | null = null;
  @column({field: 'verificationMethod', editor: 'text'})
  verificationMethod?: string | null = null;
  @column({field: 'cardLast4Digits', editor: 'text'})
  cardLast4Digits?: string | null = null;
  @column({field: 'cardType', editor: 'text'})
  cardType?: string | null = null;
  @column({field: 'productSummary', editor: 'text'})
  productSummary?: string | null = null;
  @column({field: 'localTime', editor: 'text'})
  localTime?: string | null = null;
  @column({field: 'payoutPlan', editor: 'text'})
  payoutPlan?: string | null = null;
  @column({field: 'payoutType', editor: 'text'})
  payoutType?: string | null = null;
  @column({field: 'installmentsCount', editor: 'numeric'})
  installmentsCount?: number | null = null;
  @column({field: 'simpleStatus', editor: 'text'})
  simpleStatus?: string | null = null;
  @column({field: 'payoutsReceived', editor: 'numeric'})
  payoutsReceived?: number | null = null;
  @column({field: 'payoutsTotal', editor: 'numeric'})
  payoutsTotal?: number | null = null;
  @column({field: 'taxEnabled', editor: 'boolean'})
  taxEnabled?: boolean | null = null;
  @column({field: 'authCode', editor: 'text'})
  authCode?: string | null = null;
  @column({field: 'clientTransactionId', editor: 'text'})
  clientTransactionId?: string | null = null;
  @column({field: 'internalId', editor: 'numeric'})
  internalId?: number | null = null;
  @array({field: 'products', type: 'SumUpProduct'})
  products?: SumUpProduct[] | null = null;

  override getFormControl() {
    const formControl = {
      transactionId: this.transactionId,
      transactionCode: this.transactionCode,
      merchantCode: this.merchantCode,
      username: this.username,
      amount: this.amount,
      vatAmount: this.vatAmount,
      tipAmount: this.tipAmount,
      currency: this.currency,
      timestamp: this.timestamp ? new Date(this.timestamp) : null,
      lat: this.lat,
      lon: this.lon,
      horizontalAccuracy: this.horizontalAccuracy,
      status: this.status,
      paymentType: this.paymentType,
      simplePaymentType: this.simplePaymentType,
      entryMode: this.entryMode,
      verificationMethod: this.verificationMethod,
      cardLast4Digits: this.cardLast4Digits,
      cardType: this.cardType,
      productSummary: this.productSummary,
      localTime: this.localTime ? new Date(this.localTime) : null,
      payoutPlan: this.payoutPlan,
      payoutType: this.payoutType,
      installmentsCount: this.installmentsCount,
      simpleStatus: this.simpleStatus,
      payoutsReceived: this.payoutsReceived,
      payoutsTotal: this.payoutsTotal,
      taxEnabled: this.taxEnabled,
      authCode: this.authCode,
      clientTransactionId: this.clientTransactionId,
      internalId: this.internalId,
      products: [this.products]
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
