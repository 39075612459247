import {Injectable} from '@angular/core';
import {PromoterWorkingHour} from "../../models/tables/promoter";
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";
import {TableService} from "./interfaces";
import {HttpTableBase} from "../base/http-table-base";

@Injectable({
  providedIn: 'root'
})
export class PromoterWorkingHourService extends HttpTableBase<PromoterWorkingHour> implements TableService {
  constructor(
    baseService: RestBaseService
  ) {
    super(
      {
        name: 'PromoterWorkingHoursService',
        type: PromoterWorkingHour,
        canDeleteImport: true,
        baseService: baseService,
        apiName: 'PromoterWorkingHour Service',
        apiUrl: `${environment.backendApiUrl}/promotersWorkingHours`,
        apiKey: environment.backendApiKey,
      }
    );
  }
}
