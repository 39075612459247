export interface User {
  id: string;
  username: string;
  fullname: string;
  roleType: RoleType;
  roleName: string;
}

export enum RoleType {
  developer,
  admin,
  user,
  guest,
  unknown,
}
