<div class="k-loading-panel">
  <div class="k-loading-panel-mask" *ngIf="showMask" [ngStyle]="{'width.px': width, 'height.px': height}"></div>
  <div class="k-loading-panel-wrapper" [style.top.px]="scrollTop" [style.left.px]="scrollLeft">
    <div class="k-loading-panel-frame rounded-circle shadow">
      <div class="container h-100">
        <div class="row justify-content-center align-items-center h-100">
          <div class="col-auto">
            <kendo-loader
              type="infinite-spinner"
              themeColor="primary"
              size="large"
            ></kendo-loader>

            <div class="k-loading-panel-text text-center mt-3">Loading...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
