import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule, ChipModule} from "@progress/kendo-angular-buttons";
import {IntlModule} from "@progress/kendo-angular-intl";
import {Festival, ImportStatus} from "../../models/tables/festival";
import {PopoverModule} from "@progress/kendo-angular-tooltip";
import * as customSvgIcons from "../../assets/icons/custom-svg-icons";
import {SVGIconModule} from "@progress/kendo-angular-icons";

@Component({
  selector: 'app-import-settings-chip-list',
  standalone: true,
  imports: [CommonModule, ChipModule, IntlModule, PopoverModule, ButtonModule, SVGIconModule],
  templateUrl: './import-settings-chip-list.component.html',
  styleUrls: ['./import-settings-chip-list.component.scss']
})
export class ImportSettingsChipListComponent {
  protected readonly customSvgIcons = customSvgIcons;

  @Input() event?: Festival;

  showPopover(importStatus: ImportStatus) {
    return (importStatus?.rows ?? 0) > 0 || importStatus?.imported;
  }

  getContextData = (anchor: Element) => {
    return this.event?.importStatus?.find(status => status.id === anchor.id);
  }

  isExcelImported() {
    return this.event?.importStatus
      ?.some(i => i.isExcelImport && (i.rows ?? 0) > 0);
  }

  getImportedDate() {
    return this.event?.importStatus
      ?.find(i => i.isExcelImport && i.imported)?.imported;
  }
}
