import {column, ImportTable} from "../base/base-table";

export class SumUpProduct extends ImportTable {
  @column({field: 'sumUpTransactionId', editor: 'text'})
  sumUpTransactionId?: string | null = null;
  @column({field: 'name', editor: 'text'})
  name?: string | null = null;
  @column({field: 'description', editor: 'text'})
  description?: string | null = null;
  @column({field: 'price', editor: 'numeric'})
  price?: number | null = null;
  @column({field: 'vatRate', editor: 'numeric'})
  vatRate?: number | null = null;
  @column({field: 'singleVatAmount', editor: 'numeric'})
  singleVatAmount?: number | null = null;
  @column({field: 'priceWithVat', editor: 'numeric'})
  priceWithVat?: number | null = null;
  @column({field: 'vatAmount', editor: 'numeric'})
  vatAmount?: number | null = null;
  @column({field: 'quantity', editor: 'numeric'})
  quantity?: number | null = null;
  @column({field: 'totalPrice', editor: 'numeric'})
  totalPrice?: number | null = null;
  @column({field: 'totalWithVat', editor: 'numeric'})
  totalWithVat?: number | null = null;

  override getFormControl() {
    const formControl = {
      sumUpTransactionId: this.sumUpTransactionId,
      name: this.name,
      description: this.description,
      price: this.price,
      vatRate: this.vatRate,
      singleVatAmount: this.singleVatAmount,
      priceWithVat: this.priceWithVat,
      vatAmount: this.vatAmount,
      quantity: this.quantity,
      totalPrice: this.totalPrice,
      totalWithVat: this.totalWithVat
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
