import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {debounceTime, fromEvent, Subscription, timer} from "rxjs";
import {NavbarComponent} from "../navbar/navbar.component";
import {Location, NgOptimizedImage} from "@angular/common";
import {ButtonModule} from "@progress/kendo-angular-buttons";

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    NavbarComponent,
    NgOptimizedImage,
    ButtonModule
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  private _resizeSubscription: Subscription;

  title: string;
  windowHeight: number;
  viewInitialized = false;

  constructor(
    private titleService: Title,
    private location: Location
  ) {
    this.windowHeight = window.innerHeight;

    this._resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(value => {
        const window = value.target as Window;
        if (window.innerHeight === this.windowHeight) return;
        this.windowHeight = window.innerHeight;
      });

    this.title = this.titleService.getTitle();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    timer(50)
      .subscribe(_ => this.viewInitialized = true);
  }

  ngOnDestroy(): void {
    this._resizeSubscription.unsubscribe();
  }

  onBackButtonClick() {
    this.location.back();
  }
}
