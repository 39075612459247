import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-success-box',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './success-box.component.html',
  styleUrls: ['./success-box.component.scss']
})
export class SuccessBoxComponent {
  @Input() message?: string | undefined;
}
