import {array, column, ImportTable} from "./base/base-table";
import 'reflect-metadata';
import {Module} from "./module";

export class PromoterWorkingHour extends ImportTable {
  @column({field: 'startDate', editor: 'date'})
  startDate?: string | null = null;
  @column({field: 'endDate', editor: 'date'})
  endDate?: string | null = null;
  promoter?: Promoter | null = null;
  @column({field: 'promoterId', editor: 'text'})
  promoterId?: string | null = null;
  module?: Module | null = null;
  @column({field: 'moduleId', editor: 'text'})
  moduleId?: string | null = null;

  override getFormControl() {
    const formControl = {
      startDate: this.startDate ? new Date(this.startDate) : undefined,
      endDate: this.endDate ? new Date(this.endDate) : undefined,
      promoterId: this.promoterId,
      moduleId: this.moduleId,
      promoter: this.promoter,
      module: this.module,
    };

    return Object.assign(formControl, super.getFormControl());
  }
}

export class Promoter extends ImportTable {
  @column({field: 'name', editor: 'text'})
  name?: string | null = null;
  @column({field: 'description', editor: 'text'})
  description?: string | null = null;
  @column({field: 'location', editor: 'text'})
  location?: string | null = null;
  @column({field: 'contact', editor: 'text'})
  contact?: string | null = null;
  @column({field: 'email', editor: 'text'})
  email?: string | null = null;
  @column({field: 'phone', editor: 'text'})
  phone?: string | null = null;
  @array({field: 'workingHours', type: 'PromoterWorkingHours'})
  workingHours?: PromoterWorkingHour[] | null = null;

  override getFormControl() {
    const formControl = {
      name: this.name,
      description: this.description,
      location: this.location,
      contact: this.contact,
      email: this.email,
      phone: this.phone,
      workingHours: [this.workingHours],
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
