import {GenericTable, TableArray, TableColumn} from "./interfaces";
import {Validators} from "@angular/forms";
import 'reflect-metadata';

const columnMetadataKey = Symbol('column');
const arrayMetadataKey = Symbol('array');

export function column(column: TableColumn) {
  return Reflect.metadata(columnMetadataKey, {field: column.field, editor: column.editor});
}

export function array(array: TableArray) {
  return Reflect.metadata(arrayMetadataKey, {field: array.field, type: array.type});
}

export abstract class BaseTable implements GenericTable {
  @column({field: 'id', editor: 'text'})
  id?: string | null = null;
  @column({field: 'created', editor: 'date'})
  created?: string | null = null;
  @column({field: 'deleted', editor: 'date'})
  deleted?: string | null = null;

  private getColumn(target: any, propertyKey: string): TableColumn {
    return Reflect.getMetadata(columnMetadataKey, target, propertyKey);
  }

  private getArray(target: any, propertyKey: string): TableArray {
    return Reflect.getMetadata(arrayMetadataKey, target, propertyKey);
  }

  getColumns() {
    return Object.keys(this)
      .map(key => this.getColumn(this, key))
      .filter(value => value);
  }

  getArrays() {
    return Object.keys(this)
      .map(key => this.getArray(this, key))
      .filter(value => value);
  }

  getFormControl() {
    return {
      id: [this.id, Validators.required],
      created: [this.created ? new Date(this.created) : null, Validators.required],
      deleted: [this.deleted ? new Date(this.deleted) : null],
    };
  }
}

export abstract class ImportTable extends BaseTable {
  @column({field: 'importId', editor: 'text'})
  importId?: string | null = null;
  @column({field: 'festivalId', editor: 'text'})
  festivalId?: string | null = null;

  override getFormControl() {
    const formControl = {
      importId: [this.importId, Validators.required],
      festivalId: [this.festivalId, Validators.required],
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
