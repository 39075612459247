import {array, column, ImportTable} from "../base/base-table";
import {GetSystemsItem} from "./get-systems-item";
import {GetSystemsCompany} from "./get-systems-company";
import {GetSystemsAccessCheck} from "./get-systems-access-check";
import {GetSystemsAccreditation} from "./get-systems-accreditation";
import {GetSystemsTicketValidation} from "./get-systems-ticket-validation";
import {GetSystemsFinance} from "./get-systems-finance";
import {GetSystemsPayment} from "./get-systems-payment";
import 'reflect-metadata';

export class GetSystemsTransaction extends ImportTable {
  @column({field: 'siteId', editor: 'text'})
  siteId?: string | null = null;
  @column({field: 'siteName', editor: 'text'})
  siteName?: string | null = null;
  @column({field: 'unitId', editor: 'text'})
  unitId?: string | null = null;
  @column({field: 'totalCreditsGross', editor: 'text'})
  totalCreditsGross?: string | null = null;
  @column({field: 'realCreditsGross', editor: 'text'})
  realCreditsGross?: string | null = null;
  @column({field: 'giftCreditsGross', editor: 'text'})
  giftCreditsGross?: string | null = null;
  @array({field: 'items', type: 'GetSystemsItem'})
  items?: GetSystemsItem[] | null = null;
  @column({field: 'status', editor: 'text'})
  status?: string | null = null;
  @column({field: 'updateDate', editor: 'date'})
  updateDate?: string | null = null;
  @column({field: 'tagNr', editor: 'text'})
  tagNr?: string | null = null;
  @column({field: 'sessionCounter', editor: 'numeric'})
  sessionCounter?: number;
  @array({field: 'company', type: 'GetSystemsCompany'})
  company?: GetSystemsCompany | null = null;
  @column({field: 'transactionDate', editor: 'date'})
  transactionDate?: string | null = null;
  @column({field: 'serverReceiveDate', editor: 'date'})
  serverReceiveDate?: string | null = null;
  @column({field: 'invoiceUrl', editor: 'text'})
  invoiceUrl?: string | null = null;
  @column({field: 'receiptNr', editor: 'text'})
  receiptNr?: string | null = null;
  @column({field: 'invoicePdfUrl', editor: 'text'})
  invoicePdfUrl?: string | null = null;
  @column({field: 'type', editor: 'text'})
  type?: string | null = null;
  @array({field: 'accessChecks', type: 'GetSystemsAccessCheck'})
  accessChecks?: GetSystemsAccessCheck[] | null = null;
  @array({field: 'accreditations', type: 'GetSystemsAccreditation'})
  accreditations?: GetSystemsAccreditation[] | null = null;
  @array({field: 'ticketValidations', type: 'GetSystemsTicketValidation'})
  ticketValidations?: GetSystemsTicketValidation[] | null = null;
  @array({field: 'finances', type: 'GetSystemsFinance'})
  finances?: GetSystemsFinance[] | null = null;
  @array({field: 'payment', type: 'GetSystemsPayment'})
  payment?: GetSystemsPayment | null = null;
  @column({field: 'unitName', editor: 'text'})
  unitName?: string | null = null;

  override getFormControl() {
    const formControl = {
      siteId: this.siteId,
      siteName: this.siteName,
      unitId: this.unitId,
      totalCreditsGross: this.totalCreditsGross,
      realCreditsGross: this.realCreditsGross,
      giftCreditsGross: this.giftCreditsGross,
      status: this.status,
      updateDate: this.updateDate ? new Date(this.updateDate) : null,
      tagNr: this.tagNr,
      sessionCounter: this.sessionCounter,
      transactionDate: this.transactionDate ? new Date(this.transactionDate) : null,
      serverReceiveDate: this.serverReceiveDate ? new Date(this.serverReceiveDate) : null,
      invoiceUrl: this.invoiceUrl,
      receiptNr: this.receiptNr,
      invoicePdfUrl: this.invoicePdfUrl,
      type: this.type,
      unitName: this.unitName,
      items: [this.items],
      company: this.company,
      accessChecks: [this.accessChecks],
      accreditations: [this.accreditations],
      ticketValidations: [this.ticketValidations],
      finances: [this.finances],
      payment: this.payment,
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
