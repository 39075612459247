export const ImportSources = {
  getSystemsImport: 'getSystemsImport',
  blitzenImport: 'blitzenImport',
  tonfunkImport: 'tonfunkImport',
  sumUpImport: 'sumUpImport',
  excelImport: 'excelImport',
}

export interface ImportConfig {
  id?: string;
  eventId?: string;
  getSystemsImport?: GetSystemsImport;
  blitzenImport?: BlitzenImport;
  blitzenActivationImport?: BlitzenImport;
  tonfunkImport?: TonfunkImport;
  sumUpImport?: SumUpImport;
  excelImport?: ExcelImport;
  lastImported?: string;
}

export interface ImportSource {
  id?: string;
  finished?: boolean;
  note?: string;
  importMarker?: string;
}

export function instanceOfImportSource(object: any, fieldName: string): object is ImportSource {
  return fieldName in object;
}

export interface ExcelImport extends ImportSource {
  fileName?: string;
  fileHash?: string;
}

export interface GetSystemsImport extends ImportSource {
  festivalId?: string | undefined | null;
}

export interface BlitzenImport extends ImportSource {
  brand?: string | undefined | null;
  flight?: string | undefined | null;
  teamLocation?: string | undefined | null;
}

export interface SumUpImport extends ImportSource {
  apiKey?: string | undefined | null;
  festivalId?: string | undefined | null;
  reference?: string | undefined | null;
}

export interface TonfunkImport extends ImportSource {
}
