import {column, ImportTable} from "./base/base-table";
import 'reflect-metadata';

export class Blitzen extends ImportTable {
  @column({field: 'brand', editor: 'text'})
  brand?: string | null = null;
  @column({field: 'flight', editor: 'text'})
  flight?: string | null = null;
  @column({field: 'team_location', editor: 'text'})
  team_location?: string | null = null;
  @column({field: 'promoter_id', editor: 'text'})
  promoter_id?: string | null = null;
  @column({field: 'promoter_email', editor: 'text'})
  promoter_email?: string | null = null;
  @column({field: 'gender', editor: 'text'})
  gender?: string | null = null;
  @column({field: 'age', editor: 'text'})
  age?: string | null = null;
  @column({field: 'birthdate_status', editor: 'text'})
  birthdate_status?: string | null = null;
  @column({field: 'city', editor: 'text'})
  city?: string | null = null;
  @column({field: 'zip', editor: 'text'})
  zip?: string | null = null;
  @column({field: 'country', editor: 'text'})
  country?: string | null = null;
  @column({field: 'address_status', editor: 'text'})
  address_status?: string | null = null;
  @column({field: 'address_agreement', editor: 'text'})
  address_agreement?: string | null = null;
  @column({field: 'email_status', editor: 'text'})
  email_status?: string | null = null;
  @column({field: 'email_agreement', editor: 'text'})
  email_agreement?: string | null = null;
  @column({field: 'kickbox_result', editor: 'text'})
  kickbox_result?: string | null = null;
  @column({field: 'phone', editor: 'text'})
  phone?: string | null = null;
  @column({field: 'phone_status', editor: 'text'})
  phone_status?: string | null = null;
  @column({field: 'phone_agreement', editor: 'text'})
  phone_agreement?: string | null = null;
  @column({field: 'masterbrand', editor: 'text'})
  masterbrand?: string | null = null;
  @column({field: 'session_start_time', editor: 'date'})
  session_start_time?: string | null = null;
  @column({field: 'session_end_time', editor: 'date'})
  session_end_time?: string | null = null;
  @column({field: 'session_diff_seconds', editor: 'text'})
  session_diff_seconds?: string | null = null;
  @column({field: 'signature_day', editor: 'date'})
  signature_day?: string | null = null;
  @column({field: 'tabakkategorie', editor: 'text'})
  tabakkategorie?: string | null = null;
  @column({field: 'geschmack', editor: 'text'})
  geschmack?: string | null = null;
  @column({field: 'doublette', editor: 'text'})
  doublette?: string | null = null;
  @column({field: 'state', editor: 'text'})
  state?: string | null = null;
  @column({field: 'doi_date', editor: 'date'})
  doi_date?: string | null = null;
  @column({field: 'doi_diff_seconds', editor: 'text'})
  doi_diff_seconds?: string | null = null;
  @column({field: 'app_type', editor: 'text'})
  app_type?: string | null = null;
  @column({field: 'check_purchased', editor: 'text'})
  check_purchased?: string | null = null;
  @column({field: 'check_freepacket', editor: 'text'})
  check_freepacket?: string | null = null;
  @column({field: 'answer', editor: 'text'})
  answer?: string | null = null;
  @column({field: 'route', editor: 'text'})
  route?: string | null = null;
  @column({field: 'uploaded_date', editor: 'date'})
  uploaded_date?: string | null = null;
  @column({field: 'signature_date', editor: 'date'})
  signature_date?: string | null = null;
  @column({field: 'hash_data_id', editor: 'text'})
  hash_data_id?: string | null = null;
  @column({field: 'module', editor: 'text'})
  module?: string | null = null;

  override getFormControl() {
    const formControl = {
      brand: this.brand,
      flight: this.flight,
      team_location: this.team_location,
      promoter_id: this.promoter_id,
      promoter_email: this.promoter_email,
      gender: this.gender,
      age: this.age,
      birthdate_status: this.birthdate_status,
      city: this.city,
      zip: this.zip,
      country: this.country,
      address_status: this.address_status,
      address_agreement: this.address_agreement,
      email_status: this.email_status,
      email_agreement: this.email_agreement,
      kickbox_result: this.kickbox_result,
      phone: this.phone,
      phone_status: this.phone_status,
      phone_agreement: this.phone_agreement,
      masterbrand: this.masterbrand,
      session_start_time: this.session_start_time ? new Date(this.session_start_time) : null,
      session_end_time: this.session_end_time ? new Date(this.session_end_time) : null,
      session_diff_seconds: this.session_diff_seconds,
      signature_day: this.signature_day ? new Date(this.signature_day) : null,
      tabakkategorie: this.tabakkategorie,
      geschmack: this.geschmack,
      doublette: this.doublette,
      state: this.state,
      doi_date: this.doi_date ? new Date(this.doi_date) : null,
      doi_diff_seconds: this.doi_diff_seconds,
      app_type: this.app_type,
      check_purchased: this.check_purchased,
      check_freepacket: this.check_freepacket,
      answer: this.answer,
      route: this.route,
      uploaded_date: this.uploaded_date ? new Date(this.uploaded_date) : null,
      signature_date: this.signature_date ? new Date(this.signature_date) : null,
      hash_data_id: this.hash_data_id,
      module: this.module,
    };

    return Object.assign(formControl, super.getFormControl());
  }
}
