<div *ngIf="event" class="row justify-content-lg-start justify-content-center">
  <ng-container *ngFor="let importStatus of event.importStatus">
    <!--<kendo-chip class="px-3 mx-auto mx-md-0 custom-chip" *ngIf="!importStatus.isExcelImport" size="large" rounded="full"
                [class.configured-chip]="importStatus.isConfigured" [id]="importStatus.id" kendoPopoverAnchor
                [popover]="importStatusPopover" [showOn]="showPopover(importStatus) ? 'click' : 'none'">
      <div class="container">
        <div class="row justify-content-center px-0">{{importStatus.title}}</div>
        <div class="row justify-content-between px-0 d-none d-md-flex">
          &#8203;
          <div *ngIf="importStatus.imported" class="col-auto ps-0 mx-auto">
            {{ importStatus.imported | date: 'shortDate' }}
          </div>
          <div *ngIf="(importStatus?.rows ?? 0) > 0" class="col-auto pe-0 mx-auto">
            {{ importStatus.rows | kendoNumber }}
          </div>
        </div>
      </div>
    </kendo-chip>-->
    <div class="col-auto" *ngIf="!importStatus.isExcelImport">
      <ng-template [ngTemplateOutlet]="cardTemplate"
                   [ngTemplateOutletContext]="{$implicit: importStatus}"></ng-template>
    </div>
  </ng-container>

  <div class="col-auto">
    <div class="card shadow border-0 rounded-3 mb-3 custom-card card-configured"
         [class.card-configured]="isExcelImported()" kendoPopoverAnchor [popover]="excelStatusPopover"
         [showOn]="isExcelImported() ? 'click' : 'none'">
      <div class="card-header">
        <div class="row justify-content-between align-items-center my-2">
          <div class="col-auto">
            <h6 class="card-title m-0">Excel Import</h6>
          </div>

          <!--<div class="col-auto">
            <kendo-svg-icon *ngIf="importStatus.isExcelImport" [icon]="svgIcons.xlsIcon" size="large"></kendo-svg-icon>
          </div>-->
        </div>
      </div>

      <div class="card-body d-none d-md-grid">
        <div class="card-text">
          <ng-template [ngTemplateOutlet]="excelDetailTemplate"></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #cardTemplate let-importStatus>
  <div class="card shadow border-0 rounded-3 mb-3 custom-card" [class.card-configured]="importStatus.isConfigured"
       [id]="importStatus.id" kendoPopoverAnchor [popover]="importStatusPopover"
       [showOn]="showPopover(importStatus) ? 'click' : 'none'">
    <div class="card-header">
      <div class="row justify-content-between align-items-center my-2">
        <div class="col-auto">
          <h6 class="card-title m-0">{{ importStatus.title }}</h6>
        </div>

        <!--<div class="col-auto">
          <kendo-svg-icon *ngIf="importStatus.isExcelImport" [icon]="svgIcons.xlsIcon" size="large"></kendo-svg-icon>
        </div>-->
      </div>
    </div>

    <div class="card-body d-none d-md-grid">
      <div class="card-text">
        <!--<div class="row justify-content-between">
          <div class="col-auto">Rows:</div>
          <div class="col-auto">{{ importStatus.rows | kendoNumber }}</div>
        </div>

        <div class="row justify-content-between">
          <div class="col-auto">Imported:</div>
          <div class="col-auto">{{ importStatus.imported | date: 'shortDate' }}</div>
        </div>-->
        <ng-container [ngTemplateOutlet]="importDetailTemplate"
                      [ngTemplateOutletContext]="{$implicit: importStatus}"></ng-container>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #importDetailTemplate let-importStatus>
  <div class="row justify-content-between" *ngIf="(importStatus?.rows ?? 0) > 0">
    <div class="col-auto">
      Rows:
    </div>

    <div class="col-auto">
      {{ importStatus.rows | kendoNumber }}
    </div>
  </div>

  <div class="row justify-content-between" *ngIf="importStatus.imported">
    <div class="col-auto">
      Imported:
    </div>

    <div class="col-auto">
      {{ importStatus.imported | date: 'shortDate' }}
    </div>
  </div>
</ng-template>


<kendo-popover #importStatusPopover [templateData]="getContextData" position="bottom">
  <ng-template kendoPopoverBodyTemplate let-data="data">
    <ng-container [ngTemplateOutlet]="importDetailTemplate"
                  [ngTemplateOutletContext]="{$implicit: data}"></ng-container>
  </ng-template>
</kendo-popover>


<ng-template #excelDetailTemplate>
  <div class="row justify-content-between" *ngIf="getImportedDate()">
    <div class="col-auto">
      Imported:
    </div>

    <div class="col-auto">
      {{ getImportedDate() | date: 'shortDate' }}
    </div>
  </div>

  <div class="row justify-content-center" *ngIf="isExcelImported()">
    <div class="col-auto">
      <kendo-svg-icon [icon]="customSvgIcons.checkIcon" size="large"></kendo-svg-icon>
    </div>
  </div>
</ng-template>

<kendo-popover #excelStatusPopover>
  <ng-template kendoPopoverBodyTemplate>
    <ng-container [ngTemplateOutlet]="excelDetailTemplate"></ng-container>
  </ng-template>
</kendo-popover>
