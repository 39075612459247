import {Component, Input} from '@angular/core';
import {LoaderModule} from "@progress/kendo-angular-indicators";
import {NgIf, NgStyle} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-loading-panel',
  templateUrl: './loading-panel.component.html',
  imports: [
    LoaderModule,
    NgIf,
    NgStyle
  ],
  styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent {
  @Input() height?: number
  @Input() width?: number

  @Input() scrollTop: number = 0;
  @Input() scrollLeft: number = 0;

  @Input() showMask: boolean = true

  constructor() {
  }
}
