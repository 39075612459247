import {Route} from "@angular/router";
import {HomeComponent} from "../home/home.component";
import {GuestGuard} from "../guards/guest.guard";
import {AuthGuard} from "../guards/auth.guard";
import {UserGuard} from "../guards/user.guard";
import {AdminGuard} from "../guards/admin.guard";

export const AppRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard, GuestGuard]
  },
  {
    path: 'sign-in',
    loadComponent: () => import('src/sign-in/sign-in.component')
      .then(value => value.SignInComponent)
  },
  {
    path: 'sign-in/register',
    loadComponent: () => import('src/sign-up/sign-up.component')
      .then(value => value.SignUpComponent)
  },
  {
    path: 'admin',
    loadChildren: () => import('src/admin/admin.routes')
      .then(value => value.AdminRoutes),
    canActivateChild: [AuthGuard, UserGuard]
  },
  {
    path: 'user-management',
    loadComponent: () => import('src/user-management/user-management.component')
      .then(value => value.UserManagementComponent),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'account-activation',
    loadComponent: () => import('src/account-activation/account-activation.component')
      .then(value => value.AccountActivationComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'logs',
    loadComponent: () => import('src/log/log.component')
      .then(value => value.LogComponent),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'part-matching',
    loadChildren: () => import('src/part-matching/part-matching.routes')
      .then(value => value.PartMatchingRoutes)
  },
  {
    path: 'module-matching',
    loadChildren: () => import('src/module-matching/module-matching-routes')
      .then(value => value.ModuleMatchingRoutes)
  },
];
