import {Sales} from "./sales";
import {array, column, ImportTable} from "./base/base-table";
import 'reflect-metadata';

export class Category extends ImportTable {
  @column({field: 'name', editor: 'text'})
  name?: string | null = null;
  @column({field: 'description', editor: 'text'})
  description?: string | null = null;

  override getFormControl() {
    const formControl = {
      name: this.name,
      description: this.description,
    };

    return Object.assign(formControl, super.getFormControl());
  }
}

export class Product extends ImportTable {
  @column({field: 'name', editor: 'text'})
  name?: string | null = null;
  @column({field: 'ean', editor: 'text'})
  ean?: string | null = null;
  @column({field: 'value', editor: 'numeric'})
  value?: number | null = null;
  @column({field: 'brand', editor: 'text'})
  brand?: string | null = null;
  @column({field: 'productType', editor: 'numeric'})
  productType?: ProductType | null = null;
  @array({field: 'category', type: 'Category'})
  category?: Category;
  @array({field: 'sales', type: 'Sales'})
  sales?: Sales[] | null = null;

  override getFormControl() {
    const formControl = {
      name: this.name,
      ean: this.ean,
      value: this.value,
      brand: this.brand,
      productType: this.productType,
      category: [this.category],
      sales: [this.sales],
    };

    return Object.assign(formControl, super.getFormControl());
  }
}

export enum ProductType {
  Normal,
  GiveAway
}
