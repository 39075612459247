<div #header>
  <app-navbar [title]="title"></app-navbar>

  <div style="position: relative; width: 100%; height: 10px">
    <img ngSrc="assets/images/JTI_SPECTRUM_RGB_3840x10px_CROP_01_CENTER.jpg" alt="JTI color spectrum" fill>
  </div>

  <div class="container-fluid">
    <kendo-button (click)="onBackButtonClick()" icon="arrow-left-1" themeColor="secondary" look="flat" class="mt-2">
      Back
    </kendo-button>
  </div>
</div>

<div [style.height.%]="viewInitialized ? 100 - header.clientHeight / windowHeight * 100 : 100" class="pt-2">
  <router-outlet></router-outlet>
</div>
