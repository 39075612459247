import {Injectable} from '@angular/core';
import {RestBaseService} from "../base/rest-base.service";
import {environment} from "../../environments/environment";
import {TableService} from "./interfaces";
import {Sales} from "../../models/tables/sales";
import {HttpTableBase} from "../base/http-table-base";

@Injectable({
  providedIn: 'root'
})
export class SalesService extends HttpTableBase<Sales> implements TableService {
  constructor(
    baseService: RestBaseService
  ) {
    super(
      {
        name: 'SalesService',
        type: Sales,
        canDeleteImport: true,
        baseService: baseService,
        apiName: 'Sales Service',
        apiUrl: `${environment.backendApiUrl}/sales`,
        apiKey: environment.backendApiKey,
      }
    );
  }
}
