import {Injectable} from '@angular/core';
import {IconSettingsService, SVGIcon} from "@progress/kendo-angular-icons";
import * as customSVGIcons from 'src/assets/icons/custom-svg-icons';

@Injectable()
export class MyIconService extends IconSettingsService {
  private readonly svgDictionary: { [key: string]: SVGIcon } = {
    'pencil-1': customSVGIcons.pencil1Icon,
    'undo': customSVGIcons.undoIcon,
    'cog': customSVGIcons.cogIcon,
    'arrow-down': customSVGIcons.arrowDownIcon,
    'xls': customSVGIcons.xlsIcon,
    'cloud': customSVGIcons.cloudIcon,
    'check': customSVGIcons.checkIcon,
    'arrow-left-1': customSVGIcons.arrowLeft1Icon,
  };

  public override getSvgIcon(svgIconName: string): SVGIcon {
    return this.svgDictionary[svgIconName] || super.getSvgIcon(svgIconName);
  }
}
