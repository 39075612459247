import {TableParams, TableService} from "../backend/interfaces";
import {Observable, of, switchMap} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {Operation} from "fast-json-patch/commonjs/core";
import {HttpBase} from "./http-base";
import {BaseTable} from "../../models/tables/base/base-table";

export abstract class HttpTableBase<T extends BaseTable> extends HttpBase implements TableService {
  private readonly _type: { new(): T };

  canDeleteImport: boolean;
  name: string;
  prototype: T;

  protected constructor(params: TableParams<T>) {
    super(
      params.baseService,
      params.apiName,
      params.apiUrl,
      params.apiKey
    );

    this.canDeleteImport = params.canDeleteImport;
    this.name = params.name;
    this.prototype = new params.type();
    this._type = params.type;
  }

  deleteImport(importId: string): Observable<HttpResponse<unknown>> {
    return super.delete(importId, '/import');
  }

  getData(skip?: number, take?: number, festivalId?: string): Observable<HttpResponse<unknown[]>> {
    return super.get<T[]>(`?festivalId=${festivalId ? festivalId : ''}&skip=${skip}&take=${take}`)
      .pipe(
        switchMap(value => {
          if (!value.body) return of(value);

          let models = new Array<T>();
          value.body.forEach(model => models.push(Object.assign(new this._type(), model)));
          Object.assign(value.body, models);

          return of(value);
        })
      );
  }

  override patch<T>(id: string, body: Operation[]): Observable<HttpResponse<T>> {
    return super.patch<T>(id, body);
  }

  deleteFestival(festivalId: string): Observable<HttpResponse<unknown>> {
    return super.delete(festivalId);
  }
}
